import React from 'react';
import { differenceInMinutes, format } from 'date-fns';

const TimeSince = () => {

  const startTime = new Date();
  startTime.setHours(12)
  startTime.setMinutes(8);

  // Calculate the difference in minutes between current time and start time
  const minutesDiff = differenceInMinutes(startTime, new Date());
  
  // Convert minutes to hours and remaining minutes
  const hours = Math.abs(Math.floor((minutesDiff / 60)));
  const minutes = Math.abs(Math.floor(((minutesDiff-60) % 60)));

  return (
      <p className='countup'>{hours}h {minutes}m ago </p>
  );
};

export default TimeSince;